import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Icon } from "@mostlycode/ui"
import Layout from "components/Layout"
import SEO from "components/SEO"
import Content from "components/Content"
import ArticleHeader from "components/ArticleHeader"
import ArticleBody from "components/ArticleBody"
import BrandGroups from "components/BrandElement/Groups"
import { linkResolver } from "utils/linkResolver"

const StyledArticle = styled.article`
  padding: 2rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 5rem 2rem;
  `}
`

function Article({
  data: {
    prismic: { article, brand_element_group, site_settings },
  },
  ...props
}) {
  if (!article) return null
  const {
    meta_title,
    meta_description,
    meta_images,
    title,
    subtitle,
    content,
    _meta,
  } = article
  const uri = linkResolver(_meta)
  const items = [
    {
      brand_element_group_item: brand_element_group,
    },
  ]
  return (
    <Layout site_settings={site_settings}>
      <SEO
        slug={uri}
        title={meta_title}
        description={meta_description}
        images={meta_images}
      />
      <StyledArticle>
        <BrandGroups items={items} />
        <ArticleHeader>
          <Content content={title} />
          <Content content={subtitle} />
        </ArticleHeader>
        <ArticleBody>
          <Content content={content} />
          <Icon icon="coral-trifork" />
        </ArticleBody>
      </StyledArticle>
    </Layout>
  )
}

export default Article

Article.defaultProps = {}

Article.propTypes = {}

export const query = graphql`
  query Article($lang: String!, $uid: String!, $isProduction: Boolean!) {
    prismic {
      article(lang: $lang, uid: $uid) {
        meta_title
        meta_description
        meta_images {
          image
          imageSharp @include(if: $isProduction) {
            childImageSharp {
              id
              main: fixed(quality: 80, width: 1200) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        title
        subtitle
        summary
        content
        meta_title
        _meta {
          uid
          lang
          type
        }
      }
      brand_element_group(lang: "da-dk", uid: "layout") {
        brand_group {
          color
          graphic
          parallax
        }
        _meta {
          id
        }
      }
      site_settings(lang: $lang, uid: "site-settings") {
        ...Footer
      }
    }
  }
`
